import React from 'react';
import { Form, Container } from 'react-bootstrap';
import { ACButton, useInput } from '../basic';
import { Auth } from 'aws-amplify';
import GiCancel from '@meronex/icons/gi/GiCancel';

export function RequestCodeForm(props) {
  const { setCodeSent, setUsername } = props;

  const [email, emailInput, setEmail, setEmailValid] = useInput({
    placeholder: 'Enter Username',
    value: ''
  });

  function handleSendConfirmation() {
    Auth.forgotPassword(email).then(response => {
      setCodeSent(true);
      setUsername(email);
    });
  }
  return (
    <div className={'ac-form-box'}>
      <Form inline style={{ marginBottom: '15px' }}>
        <Container fluid>
          <Form.Label className={'usernameac'}></Form.Label>
          {emailInput}
          <ACButton className={'ml-4'} onClick={() => handleSendConfirmation()}>
            {' '}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Send
            Code&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
          </ACButton>
        </Container>
      </Form>
    </div>
  );
}
