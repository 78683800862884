import styled from 'styled-components';
import ReactNotification from 'react-notifications-component';
import _ from 'lodash';
import {
  BrowserRouter as Router,
  Switch,
  HashRouter,
  Route,
  Link,
  Redirect
} from 'react-router-dom';

import React from 'react';

import DatePicker from 'react-datepicker';

// import { ACSwitch } from '../app/ACSwitch';
// import { ACProvider } from '../provider';
// import { ACNavbar } from '../navbar';
// import { ACSidebar } from '../sidebar';

import FaCalendarAlt from '@meronex/icons/fa/FaCalendarAlt';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

// ***********************
// ***********************
// ***********************

export const Root = styled.div`
  display: flex;
  flex-direction: row;
  background: #e4ecfb;
  height: 100%;
  padding-top: 56px;
  position: relative;
`;
export const Content = styled.div`
  flex: 1;
  padding-left: 230px;
  transition: all 0.3s ease-out 0s;
  overflow: auto;
  ${props => (props.showSidebar ? '' : 'padding-left: 30px;')}
`;

export const ContentChild = styled.div`
  padding: 40px 22px;
  padding: 40px 40px;
  height: 100%;
  overflow: auto;
  position: relative;
`;
// ***********************
// ***********************
// ***********************
export const ACBox = styled.div`
  background: white;
  margin-bottom: 18px;
  padding: 10px 15px;
  box-shadow: 0px 0px 10px 0px rgba(74, 133, 249, 0.25);
  border-radius: 4px;
  padding: 0;

  /* Contextual selectors work as well */
  body.ac-light & {
    border: 1px solid rgba(0, 46, 103, 0.25);
    box-shadow: 0px 0px 2px 0px rgba(0, 46, 103, 0.25);
  }
`;

export const ACBoxBody = styled.div`
  padding: 10px 15px;
`;

export const ACHeader = styled.div`
  min-height: 30px;
  padding: 6px 10px;
  display: flex;
  transition: all 0.3s ease-out 0s;
  align-items: center;
  ${props => (props.border ? `border-bottom: 1px solid #ccc;` : '')}

  background: #eeeeee;
  border: 1px solid #c7c7c7;

  /* Contextual selectors work as well */
  body.ac-light & {
    border: none;
    border-bottom: 1px solid #c7c7c7;
  }
`;

export const ACHeaderContent = styled.div`
  display: flex;
  justify-content: space-around;
  font-size: 1.7rem;
  padding-left: 0px;
  ${props => (props.flex ? `flex:${props.flex}` : '')}
  ${props => (props.basis ? `flex-basis:${props.basis}` : '')}
`;

export const ACRow = styled.div`
  min-height: 30px;
  padding: 6px 0px;
  display: flex;
  transition: all 0.3s ease-out 0s;
  align-items: center;
  ${props => (props.bordernone ? `` : 'border-bottom: 1px solid #ccc;')}
  &:hover {
    background: #e4ecfb;
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const ACContent = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 10px;
  ${props => (props.flex ? `flex:${props.flex}` : '')}
  ${props => (props.basis ? `flex-basis:${props.basis}` : '')}
`;

export const ACButton = styled.span`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  background: #0786d2;
  border-radius: 10px;
  cursor: pointer;
  color: #e4ecfb;
  padding: 4px 8px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  & svg,
  & .spinner-border {
    margin-right: 5px;
    height: 16px;
    width: 16px;
  }
  & svg path {
    stroke: #e4ecfb !important;
  }
  & svg polygon {
    stroke: #e4ecfb !important;
  }

  &:hover {
    // background-color: #0062cc;
    // border-color: #005cbf;
    ${props =>
      props.disabled
        ? ``
        : `
    background-color: #0062cc;
    border-color: #005cbf;`}
  }

  ${props => (props.disabled ? `background-color: #7a7a7a;cursor: not-allowed;` : '')}
`;

export const ACSpinnerFS = styled.div`
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ACSpinnerFullScreen = props => {
  return (
    <ACSpinnerFS>
      <Spinner animation='border' variant='primary' />
    </ACSpinnerFS>
  );
};

export const ACRequired = styled.span`
  color: red;
`;
const CustomInput = React.forwardRef((props, ref) => {
  const { value, onClick, placeholder } = props;
  return (
    <InputGroup>
      <input
        type='text'
        className='form-control form-control-sm'
        placeholder={placeholder ? placeholder : 'Select Date'}
        value={value}
        readOnly
        // onChange={e => setTxtActivity(e.target.value)}
        onClick={onClick}
      />
      <InputGroup.Append>
        <Button variant='outline-secondary' onClick={onClick}>
          <FaCalendarAlt size={18} />
        </Button>
      </InputGroup.Append>
    </InputGroup>
  );
});
CustomInput.displayName = 'CustomInput';
export const ACDTPicker = ({
  showTimeInput = false,
  date = null,
  setDate = _.noop,
  ...rest
}) => {
  // const [date, setDate] = React.useState(new Date());
  if (showTimeInput) {
    return (
      <DatePicker
        dateFormat='dd-MMM-yyyy h:mm aa'
        closeOnScroll={true}
        selected={date}
        showMonthDropdown
        showYearDropdown
        dropdownMode='select'
        onChange={date => setDate(date)}
        timeInputLabel='Time:'
        showTimeInput
        customInput={<CustomInput />}
        {...rest}
      />
    );
  }

  return (
    <DatePicker
      dateFormat='dd-MMM-yyyy'
      closeOnScroll={true}
      selected={date}
      showMonthDropdown
      showYearDropdown
      dropdownMode='select'
      onChange={date => setDate(date)}
      // onChangeRaw raw text field
      customInput={<CustomInput />}
      {...rest}
    />
  );
};

export { useInput } from './useInput';
export { UpdatingPopover } from './updatingPopover';

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
