import React from 'react';
import { Modal } from 'react-bootstrap';
import { RequestCodeForm } from './RequestCodeForm';
import { ConfirmationForm } from './ConfirmationForm';
import { SuccessMessage } from './SuccessMessage';

export function PasswordResetModal(props) {
  const [username, setUsername] = React.useState('');
  const [codeSent, setCodeSent] = React.useState(false);
  const [confirmed, setConfirmed] = React.useState(false);
  const { show, closeModal } = props;

  function closeResetModal() {
    setUsername('');
    setCodeSent(false);
    setConfirmed(false);
    closeModal();
  }
  return (
    <Modal {...props} show={show} onHide={() => closeResetModal()} keyboard centered>
      <Modal.Header closeButton>
        <Modal.Title className="text-center"> Reset Password </Modal.Title>
      </Modal.Header>
      {!codeSent ? (
        <RequestCodeForm
          setCodeSent={value => setCodeSent(value)}
          setUsername={value => setUsername(value)}
        />
      ) : !confirmed ? (
        <ConfirmationForm
          confirmationSuccess={value => setConfirmed(value)}
          username={username}
        />
      ) : (
        <SuccessMessage />
      )}
    </Modal>
  );
}
