export const COMMISSION_TYPES = [
  { id: 'PERCENTAGE', label: 'PERCENTAGE' },
  { id: 'FLAT', label: 'FLAT' }
];

export const COMMISSION_STATUS = [
  // { id: 'Draft', label: 'Draft' },
  { id: 'Staged', label: 'Staged' },
  { id: 'Review', label: 'Review' },
  { id: 'Approved', label: 'Approved' },
  { id: 'Settled', label: 'Settled' },
  { id: 'Paid', label: 'Paid' }
];
