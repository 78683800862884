import React from 'react';
import styled from 'styled-components';

import Form from 'react-bootstrap/Form';

const ACFieldContainer = styled.span`
  display: inline-block;
`;

export const ACFeedback = styled.span`
  font-size: 1.2rem;
  display: block;
  ${props => (props.type && props.type === 'valid' ? 'color: #28a745;' : '')}
  ${props => (props.type && props.type === 'invalid' ? 'color: #dc3545;' : '')}
`;

export function useInput({ type = 'text', placeholder = '', value = '' }) {
  const [inputValue, setValue] = React.useState(value);
  const [feedback, setFeedback] = React.useState();
  const input = (
    <ACFieldContainer>
      <Form.Control
        type={type}
        value={inputValue}
        onChange={e => setValue(e.target.value)}
        placeholder={placeholder}
        size='sm'
        maxLength={40}
        className={'mr-sm-2'}
        isInvalid={feedback && feedback.type === 'invalid' ? true : false}
        isValid={feedback && feedback.type === 'valid' ? true : false}
      />
      {feedback ? <ACFeedback type={feedback.type}>{feedback.msg}</ACFeedback> : null}
    </ACFieldContainer>
  );
  return [inputValue, input, setValue, setFeedback];
}
