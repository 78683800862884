import React, { Suspense, lazy } from 'react';
import { Auth } from 'aws-amplify';
import {
  HashRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory
} from 'react-router-dom';

import { v4 } from 'uuid';

import { LoginPage } from '../../login';
import { ACAppSpinner } from './ACAppSpinner/ACAppSpinner';
import { AC_CONSTANTS } from '../../utils';
import { ErrorPage } from './support/error/ErrorPage';
import { useUser } from '../../user/reducer/UserActions';
import { InactiveErrorPage } from './support/error/InactiveErrorPage';

const PrivateSwitchApp = lazy(async () => await import('./private/PrivateSwitchApp'));

export function PublicSwitch(props) {
  return (
    <Router>
      <>
        {/* 
        <ul>
          <li>
            <Link to="/public">Public Page</Link>
          </li>
          <li>
            <Link to={`/protected`}>Protected Page</Link>
          </li>
        </ul> */}
        <PublicSwitchChild {...props} />
      </>
    </Router>
  );
}

export function PublicSwitchChild({ storybookConfig }) {
  const history = useHistory();
  React.useEffect(() => {
    if (storybookConfig && storybookConfig.isStorybook) {
      history.push(storybookConfig.acroute);
    }
  }, [storybookConfig]);
  return (
    <Suspense fallback={<ACAppSpinner />}>
      <Switch>
        <Route path='/login'>
          <LoginPage />
        </Route>
        <Route path='/404'>
          <ErrorPage />
        </Route>
        <Route path='/inactive'>
          <InactiveErrorPage />
        </Route>
        <PrivateRoute path={`/${AC_CONSTANTS.APP_NAME}`}>
          <PrivateSwitchApp />
        </PrivateRoute>
        <Route path='/'>
          <LoginPage />
        </Route>
      </Switch>
    </Suspense>
  );
}
// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
  const [isAuthenticated, setIsAuthenticated] = React.useState(null);

  const userData = useUser();
  const currentUser = userData.data[0] || {};

  React.useEffect(() => {
    async function checkAuthentication() {
      let user = await Auth.currentAuthenticatedUser().catch(err => {
        console.log('User is not logged in', err);
      });
      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    }
    checkAuthentication();
  }, []);
  if (isAuthenticated === null) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (currentUser.isActiveStatus !== 'Y') {
          return (
            <Redirect
              to={{
                pathname: '/inactive',
                state: { from: location }
              }}
            />
          );
        }
        return isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        );
      }}
    />
  );
}
