import { serviceContext } from '../../';
import axios from 'axios';

export const getUserData = email => {
  return axios({
    method: 'get',
    url: `${serviceContext}/userView/email/${email}`,
    data: {}
  }).then(({ data }) => {
    // return JSON.parse(data);
    return data;
  });
};
