import React from 'react';
import './app-spinner.scss';

export function ACAppSpinner() {
  return (
    <>
      <div className="ac-loader-container">
        <div className="ac-loader">
          <div className="rect1"></div>
          <div className="rect2"></div>
          <div className="rect3"></div>
          <div className="rect4"></div>
          <div className="rect5"></div>
        </div>
      </div>
    </>
  );
}
