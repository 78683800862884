import './css/login.scss';
import React from 'react';
import _ from 'lodash';
import {
  ACBox,
  ACBoxBody,
  ACHeader,
  ACHeaderContent,
  ACRow,
  ACContent,
  ACButton,
  ACLayout
} from '../basic';
import Modal from 'react-bootstrap/Modal';

import CardColumns from 'react-bootstrap/CardColumns';
import Card from 'react-bootstrap/Card';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import FiLogIn from '@meronex/icons/fi/FiLogIn';
import { useAppHeartBeat } from '../app-new/reducer/HeartbeatActions';
import { useUser, setUser } from '../user/reducer/UserActions';
import { getUserData } from '../user/reducer/UserService';
import { AC_CONSTANTS } from '../utils';
import { PasswordResetModal } from './PasswordResetModal';
import { Auth } from 'aws-amplify';
import Spinner from 'react-bootstrap/Spinner';
import aws from 'aws-sdk';

import { useHistory, useLocation } from 'react-router-dom';

export function LoginPage(props) {
  let history = useHistory();
  let location = useLocation();

  let { from } = location.state || { from: { pathname: '/' } };

  const user = useUser();
  const appConfig = useAppHeartBeat();
  const [userData, setUserData] = React.useState({
    // username: 'agentac@synergytechs.net',
    // password: 'Synergy123!'
  });

  const [loginLoading, setLoginLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [openPasswordResetModal, setOpenPasswordResetModal] = React.useState(false);
  const currentUser = user.data[0] || {};
  React.useEffect(() => {
    if (!_.isEmpty(currentUser)) {
      if (from.pathname === '/') {
        history.push(`/${AC_CONSTANTS.APP_NAME}/`);
      } else {
        history.replace(from);
      }
    }
  }, [currentUser]);
  function handleInputChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    setErrorMessage('');
    setUserData({ ...userData, [name]: value });
  }

  function login() {
    if (_.isEmpty(userData.username) || _.isEmpty(userData.password)) {
      setErrorMessage('Username & Password cannot be empty');
      return;
    }
    setLoginLoading(true);
    Auth.signIn(userData.username, userData.password)
      .then(user => {
        aws.config.region = appConfig.data[0].region;
        aws.config.credentials = new aws.CognitoIdentityCredentials({
          IdentityPoolId: appConfig.data[0].identityPoolId,
          Logins: {
            ['cognito-idp.us-east-2.amazonaws.com/' + appConfig.data[0].userPoolId]: user
              .signInUserSession.idToken.jwtToken
          }
        });
        aws.config.credentials.clearCachedId();
        setLoginLoading(false);
        setErrorMessage('');
        getUserData(userData.username)
          .then(response => {
            if (response.ok) {
              const userObj = response.data[0];
              setUser({
                ...userObj,
                userId: userObj.userId,
                firstName: userObj.firstName,
                lastName: userObj.lastName,
                userRoles: userObj.userRoles,
                userSkill: userObj.userSkill,
                mode: AC_CONSTANTS.AGENT_MODE.OUTBOUND,
                clientId: AC_CONSTANTS.HARDCODING_clientId,
                sessionToken: user.signInUserSession.idToken.jwtToken
              });
              console.log('the current logged in user is ' + user.data);
              if (from.pathname === '/') {
                history.push(`/${AC_CONSTANTS.APP_NAME}/`);
              } else {
                history.replace(from);
              }
            } else {
              console.error(response);
            }
          })
          .catch(err => {
            console.error(err.message);
          });
      })
      .catch(err => {
        setLoginLoading(false);
        setErrorMessage(err.message);
      });
  }

  return (
    <>
      <div className={'login-page'}>
        <Container fluid>
          <Row>
            <Col sm={7}></Col>
            <Col sm={3}>
              <Form className={'login-form'}>
                <div className={'login-title'}>Login to your favorite CRM</div>
                <Form.Row className={'username'}>
                  <Col>
                    <Form.Label style={{ color: 'red' }}>{errorMessage}</Form.Label>
                    <Form.Label className={'usernameac'}></Form.Label>
                    <Form.Control
                      className='mb-2'
                      id='inlineFormInput'
                      placeholder='Username'
                      size='xl'
                      name='username'
                      onChange={e => handleInputChange(e)}
                      onKeyPress={event => {
                        const code = event.keyCode || event.which;
                        if (code === 13) {
                          //13 is the enter keycode
                          login();
                        }
                      }}
                    />
                  </Col>
                </Form.Row>
                <Form.Row className={'password'}>
                  <Col>
                    <Form.Label className={'passwordac'}></Form.Label>
                    <Form.Control
                      className='mb-2'
                      id='inlineFormInput'
                      placeholder='Password'
                      size='xl'
                      type='password'
                      name='password'
                      onChange={e => handleInputChange(e)}
                      onKeyPress={event => {
                        const code = event.keyCode || event.which;
                        if (code === 13) {
                          //13 is the enter keycode
                          login();
                        }
                      }}
                    />
                  </Col>
                </Form.Row>
                <br />
                <Form.Row>
                  <Col>
                    <ACButton className='mr-auto' onClick={() => login()}>
                      {loginLoading ? (
                        <Spinner
                          as='span'
                          animation='border'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        <FiLogIn />
                      )}
                      Login
                    </ACButton>

                    <a
                      href='#'
                      className={'forgot-pwd-link'}
                      onClick={() => setOpenPasswordResetModal(true)}
                    >
                      Forgot Password?
                    </a>
                  </Col>
                </Form.Row>
              </Form>
            </Col>
          </Row>
        </Container>
        <PasswordResetModal
          show={openPasswordResetModal}
          closeModal={() => setOpenPasswordResetModal(false)}
        />
      </div>
    </>
  );
}
