import { useRedux } from '../../provider/hooks-for-redux';
import { AC_CONSTANTS } from '../../utils';

export const [useUser, { setUser, setUserReducer }] = useRedux(
  'user',
  {
    error: false,
    errorMsg: '',
    data: [
      // {
      //   userId: 1,
      //   firstName: 'Yogi',
      //   lastName: 'Admin',
      //   userSkill: 'LFE,LTC,M,V',
      //   userRoles: 'Admin',
      //   mode: AC_CONSTANTS.AGENT_MODE.OUTBOUND
      // },
      // {
      //   userId: 2,
      //   firstName: 'Yogi',
      //   lastName: 'Supervisor',
      //   userSkill: 'LFE,LTC,M,V',
      //   userRoles: 'Supervisor'
      // },
      // {
      //   // userId: 3,
      //   userId: 26,
      //   firstName: 'Agent',
      //   lastName: 'Agency',
      //   userRoles: 'Agent',
      //   userSkill: 'LFE,LTC,M,V',
      //   mode: AC_CONSTANTS.AGENT_MODE.OUTBOUND
      //   // mode: AC_CONSTANTS.AGENT_MODE.INBOUND,
      //   // mode: AC_CONSTANTS.AGENT_MODE.CALLCENTER
      // }
      // {
      //   userId: 4,
      //   firstName: 'Yogi',
      //   lastName: 'Analyst',
      //   userSkill: 'LFE,LTC,M,V',
      //   userRoles: 'Analyst'
      // }
    ]
  },
  {
    setUser: (user, newUser) => ({
      ...user,
      error: false,
      errorMsg: '',
      data: [newUser]
    }),
    setUserReducer: (oldState, newState) => ({
      ...oldState,
      ...newState
    })
  }
);
