import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/fonts/Roboto/font.css';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import 'react-datepicker/dist/react-datepicker.css';

import 'animate.css/animate.compat.css';
import 'react-notifications-component/dist/theme.css';
import 'react-phone-input-2/lib/style.css';

import './css/app.scss';
import '../navbar/css/navbar.scss';
import '../basic/phoneinput/css/phone.scss';
import '../basic/multiselect/css/multiselect.scss';
import './css/light.scss';

import React from 'react';
import { ACProvider } from '../provider';
import { LoginManager } from './__components__/LoginManager';

export function LoginApp(props) {
  return (
    <ACProvider>
      <LoginManager {...props} />
    </ACProvider>
  );
}
