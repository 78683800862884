// eslint-disable-next-line no-unused-vars
import moment from 'moment';
import 'moment-timezone';

/* polyfills.js */
import 'react-app-polyfill/ie11';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';
/* polyfills.js */

// import { App as DummyApp } from './app/App';
import { LoginApp as App } from './app-new/LoginApp';
// import { ACProvider } from './provider';

Number.isInteger =
  Number.isInteger ||
  function (value) {
    return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
  };

// console.log('Index init');

export {
  serviceContext,
  serviceContextCloudBatch,
  isMock
} from './provider/serviceContext';

export { App };
// export { DummyApp, App };
// export { DummyApp, App };
// export { DummyApp };

// export { Carrier } from './carrier';
// export { Product } from './product';
// export {
//   ACBox,
//   ACHeader,
//   ACHeaderContent,
//   ACRow,
//   ACContent,
//   ACButton,
//   ACLayout
// } from './basic';
// export { AC_CONSTANTS } from './utils';

// //TODO Yogi
// window.moment = moment;
// initApp();
// //TODO Yogi
