/* eslint-disable no-useless-escape */
import moment from 'moment';

import { GENDER_LIST } from './constants/gender';
import { COMMISSION_TYPES, COMMISSION_STATUS } from './constants/commissionTypes';
import { PRODUCT_GROUP_TYPES } from './constants/productGroupTypes';
import { MENU_LIST_AGENT } from './constants/menu';
import { LANGUAGES } from './constants/language';
import { US_STATES } from './constants/states';
// import { DISPOSITION } from './constants/disposition';
import { ACTIVITY_STATUS } from './constants/activityStatus';
import { AGENT_MODE } from './constants/mode';
import { PAGE_ID } from './constants/pageIds';
import { CAMPAIGN_TYPES_MASTER } from './constants/campaignTypes';
import { CUSTOMER_STATUS } from './constants/customerStatus';

// const generateMonths = () => {
//   const AC_MONTHS = [];

//   const dateStart = moment('2018-01-01'); //'2020-09-01'
//   const dateEnd = moment();

//   while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
//     AC_MONTHS.push({
//       label: dateStart.format('YYYY-MM'),
//       month: dateStart.format('MM'),
//       year: dateStart.format('YYYY')
//     });
//     dateStart.add(1, 'month');
//   }
//   return AC_MONTHS;
// };

export const AC_CONSTANTS = {
  APP_NAME: 'crm',
  GENDER_LIST,
  COMMISSION_TYPES,
  COMMISSION_STATUS,
  PRODUCT_GROUP_TYPES,
  MENU_LIST_AGENT,
  LANGUAGES,
  US_STATES,
  CAMPAIGN_TYPES_MASTER,
  CUSTOMER_STATUS,
  // DISPOSITION,
  AGENT_MODE,
  ACTIVITY_STATUS,
  AC_MONTH_FORMAT: 'MMM-YYYY', // Display Month on UI
  AC_DATE_FORMAT: 'DD-MMM-YYYY', // Display Date on UI
  AC_DATE_TIME_FORMAT: 'DD-MMM-YYYY HH:mm:ss', // Display Date Time on UI
  AC_DATE_FORMAT_SERVICE: 'YYYY-MM-DD HH:mm:ss', // used to save & retrieve date/time from serviec
  AC_ONLY_DATE_FORMAT_SERVICE: 'YYYY-MM-DD 00:00:00', // used to save & retrieve date from serviec
  AC_PHONE_PLACEHOLDER: '(***) ***-****',
  AC_ONLY_NUMBER: /^[0-9\b]+$/,
  AC_ONLY_DECIMAL_NUMBER: /^\s*-?(\d+(\.\d{0,2})?|\.\d{0,2})\s*$/,
  AC_ONLY_ALPHABETS_OLD: /^[A-Z]+$/i,
  AC_ONLY_ALPHABETS: /^[A-Z\s\.\-]+$/i, // \s for space  \- for hypen \. for dot \/ for slash
  AC_EMAIL_REGEX:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  AC_ONLY_ALPHA_NUMERIC: /^[a-z0-9]+$/i,
  AC_ONLY_ALPHA_NUMERIC_WITH_SPACE_HYPEN: /^[a-z0-9\s\-]+$/i,
  PAGE_ID,
  // AC_MONTHS: generateMonths,
  // HARDCODING
  HARDCODING_clientId: 1,
  HARDCODING_sourceId: 1,
  HARDCODING_campaignId: 1,
  HARDCODING_SOLD: 'Win'
  // HARDCODING
};
