import React from 'react';
import { Form, Container, Col } from 'react-bootstrap';
import { ACButton, useInput } from '../basic';
import { Auth } from 'aws-amplify';
import GiCancel from '@meronex/icons/gi/GiCancel';

export function ConfirmationForm(props) {
  const { username, confirmationSuccess } = props;
  const [errorMessage, setErrorMessage] = React.useState('');
  const [newPassword, newPasswordInput, setNewPassword, setValidNewPassword] = useInput({
    placeholder: 'New Password',
    value: ''
  });

  const [
    confirmPassword,
    confirmPasswordInput,
    setConfirmPassword,
    setValidConfirmPassword
  ] = useInput({
    placeholder: 'Confirm Password',
    value: ''
  });

  const [
    verificationCode,
    verificationCodeInput,
    setVerificationCode,
    setValidVerificationCode
  ] = useInput({
    placeholder: 'Verification Code',
    value: ''
  });

  function validationText() {
    if (newPassword != '') {
      if (confirmPassword !== '') {
        if (newPassword !== confirmPassword) {
          return 'The password did not match';
        }
      } else {
        return 'Confirm Password is required';
      }
    }
  }

  function handleConfirmSubmit() {
    if (verificationCode == '' || newPassword == '') {
      setErrorMessage("Invalid form entries, couldn't proceed with request.");
    } else {
      setErrorMessage('');
      Auth.forgotPasswordSubmit(username, verificationCode, newPassword)
        .then(response => {
          confirmationSuccess(true);
        })
        .catch(error => {
          alert(error.message);
        });
    }
  }

  return (
    <div className={'ac-form-box'}>
      <Form>
        <Container fluid>
          <Form.Label style={{ color: 'red' }}>{errorMessage}</Form.Label>
          <Form.Label>
            Please check your mail box {username} for the verification code
          </Form.Label>
          <br />
          <Form.Row className={'username'}>
            <Col>
              <Form.Group>
                <Form.Control
                  type="number"
                  placeholder="Verification Code"
                  size="md"
                  value={verificationCode}
                  onChange={e => {
                    setErrorMessage('');
                    setVerificationCode(e.target.value);
                  }}
                />
              </Form.Group>
              <br />
            </Col>
          </Form.Row>
          <Form.Row className={'username'}>
            <Col>
              <Form.Group>
                <Form.Control
                  type="password"
                  placeholder="New Password"
                  disabled={verificationCode != '' ? false : true}
                  size="md"
                  value={newPassword}
                  onChange={e => {
                    setErrorMessage('');
                    setNewPassword(e.target.value);
                  }}
                />
              </Form.Group>
              <br />
            </Col>
          </Form.Row>
          <Form.Row className={'password'}>
            <Col>
              <Form.Group>
                <Form.Control
                  type="password"
                  placeholder="Confirm Password"
                  size="md"
                  disabled={verificationCode != '' ? false : true}
                  value={confirmPassword}
                  onChange={e => {
                    setErrorMessage('');
                    setConfirmPassword(e.target.value);
                  }}
                />
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Label style={{ color: 'red' }}>{validationText()}</Form.Label>
            </Col>
          </Form.Row>
          <Form.Row className={'buttons'}>
            <Col disabled={verificationCode != '' ? false : true}>
              <ACButton className={'ml-4 center'} onClick={() => handleConfirmSubmit()}>
                {' '}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                Confirm&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </ACButton>
            </Col>
          </Form.Row>
        </Container>
      </Form>
    </div>
  );
}
