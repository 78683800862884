import React from 'react';
import '../css/error.scss';

import styled from 'styled-components';
import MdHammer from '@meronex/icons/ios/MdHammer';

const Root = styled.div`
  height: 100%;
`;
const ErrorContainer = styled.div`
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export function MaintainencePage(props) {
  return (
    <Root className={'support-body'}>
      <ErrorContainer>
        <MdHammer size={256} style={{ color: '#444' }} />
        <div className='title'>
          Well this is a bit embarrassing! CRM is under maintenance.
        </div>
      </ErrorContainer>
    </Root>
  );
}
