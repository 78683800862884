export const MENU_LIST_AGENT = [
  {
    menuId: '1',
    menuLinkKey: 'MY_ACTIVE_LEADS'
  },
  {
    menuId: '2',
    menuLinkKey: 'MY_COMPLETED_LEADS'
  },
  {
    menuId: '3',
    menuLinkKey: 'MY_NEW_LEADS'
  }
];
