import React from 'react';

import { getAwsConfigurations } from '../__config__/awsConfig';
import { PublicSwitch } from './PublicSwitch';

import { Auth } from 'aws-amplify';
import Amplify from 'aws-amplify';
import aws from 'aws-sdk';
import { getUserData } from '../../user/reducer/UserService';

import { useAppConfig, setAppConfig } from '../reducer/AppActions';
import { setAppHeartBeat } from '../reducer/HeartbeatActions';
import { setUserReducer } from '../../user/reducer/UserActions';
import { ACAppSpinner } from './ACAppSpinner/ACAppSpinner';
import { AC_CONSTANTS } from '../../utils';
import { MaintainencePage } from './support/maintainence/MaintainencePage';
import { ErrorPage } from './support/error/ErrorPage';

export function LoginManager(props) {
  const AppConfig = useAppConfig();

  React.useEffect(() => {
    //init aws
    awsInit();
  }, []);
  const awsInit = async () => {
    setAppConfig({
      appLoading: true,
      error: false,
      errorMsg: '',
      appStatus: null
    });

    let CRMConfig = await getAwsConfigurations().catch(() => {
      console.error('error in fetching config');
      setAppConfig({
        appLoading: false,
        error: true,
        errorMsg: 'SERVICE_DOWN'
      });
    });
    if (CRMConfig && CRMConfig.data) {
      console.log(CRMConfig);

      Amplify.configure({
        Auth: {
          userPoolId: CRMConfig.data[0].userPoolId,
          userPoolWebClientId: CRMConfig.data[0].userPoolWebClientId,
          region: CRMConfig.data[0].region,
          identityPoolId: CRMConfig.data[0].identityPoolId
        }
      });
      let heartbeatObj = {
        loading: false
      };
      heartbeatObj = { ...heartbeatObj, data: [...CRMConfig.data] };
      setAppHeartBeat(heartbeatObj);
      //   let userSession = await Auth.currentSession().catch(err => {
      //     // User is not logged in
      //     console.log('User is not logged in', err);
      //     setUserReducer({
      //       userLoading: false,
      //       error: false,
      //       errorMsg: '',
      //       data: []
      //     });

      //     setAppConfig({
      //       appLoading: false,
      //       error: false,
      //       errorMsg: '',
      //       appStatus: 'SUCCESS'
      //     });
      //   });

      let user = await Auth.currentAuthenticatedUser().catch(err => {
        // User is not logged in
        console.log('User is not logged in', err);
        setUserReducer({
          userLoading: false,
          error: false,
          errorMsg: '',
          data: []
        });

        setAppConfig({
          appLoading: false,
          error: false,
          errorMsg: '',
          appStatus: 'SUCCESS'
        });
      });
      if (user) {
        // user is logged in cognito

        const { attributes } = user;

        const CRMUser = await getUserData(attributes.email).catch(err => {
          console.log(err);
          setUserReducer({
            userLoading: false,
            error: false,
            errorMsg: '',
            data: []
          });
        });
        if (CRMUser && CRMUser.ok) {
          const userObj = CRMUser.data[0];
          setUserReducer({
            userLoading: false,
            error: false,
            errorMsg: '',
            data: [
              {
                ...userObj,
                userId: userObj.userId,
                firstName: userObj.firstName,
                lastName: userObj.lastName,
                userRoles: userObj.userRoles,
                userSkill: userObj.userSkill,
                mode: AC_CONSTANTS.AGENT_MODE.OUTBOUND,
                clientId: AC_CONSTANTS.HARDCODING_clientId,
                sessionToken: user.signInUserSession.idToken.jwtToken
              }
            ]
          });
          setAppConfig({
            appLoading: false,
            error: false,
            errorMsg: '',
            appStatus: 'SUCCESS'
          });
        }

        aws.config.region = heartbeatObj.data[0].region;
        aws.config.credentials = new aws.CognitoIdentityCredentials({
          IdentityPoolId: heartbeatObj.data[0].identityPoolId,
          Logins: {
            ['cognito-idp.us-east-2.amazonaws.com/' + heartbeatObj.data[0].userPoolId]:
              user.signInUserSession.idToken.jwtToken
          }
        });
        aws.config.credentials.clearCachedId();
      }
    }
  };
  if (AppConfig.appLoading) {
    return (
      <>
        <ACAppSpinner />
      </>
    );
  }
  // return <MaintainencePage />;
  if (AppConfig.error) {
    if (AppConfig.errorMsg === 'SERVICE_DOWN') {
      return <MaintainencePage />;
    } else {
      return <h1>{AppConfig.errorMsg}</h1>;
    }
  }
  if (AppConfig.appStatus === 'SUCCESS') {
    return (
      <>
        <PublicSwitch {...props} />
      </>
    );
  }
  return null;
}
