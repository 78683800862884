import React from 'react';
import { Form, Container } from 'react-bootstrap';
export function SuccessMessage(props) {
  return (
    <div className={'ac-form-box'}>
      <Form>
        <Container fluid>
          <Form.Label style={{ color: 'green' }}>
            Password successfully updated, please close this window and login.
          </Form.Label>
        </Container>
      </Form>
    </div>
  );
}
