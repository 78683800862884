import React from 'react';
import '../css/error.scss';

import styled from 'styled-components';

import EnEmojiSad from '@meronex/icons/en/EnEmojiSad';
import MdArrowRoundBack from '@meronex/icons/ios/MdArrowRoundBack';
import { AC_CONSTANTS } from '../../../../utils';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';

const Root = styled.div`
  height: 100%;
`;
const ErrorContainer = styled.div`
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export function InactiveErrorPage(props) {
  let history = useHistory();
  const handleGoHome = () => {
    // history.push(`/${AC_CONSTANTS.APP_NAME}/dashboard`);
    history.goBack();
  };
  React.useEffect(() => {
    Auth.signOut().then(() => {
      console.log('User Signout');
    });
  }, []);
  return (
    <Root className={'support-body'}>
      <div className='options'>
        <div className='options-1'>
          <span className={'go-back'} onClick={handleGoHome}>
            <MdArrowRoundBack size={40} />
            <div className='options-text'>Back</div>
          </span>
        </div>
      </div>
      <ErrorContainer>
        <EnEmojiSad size={256} style={{ color: '#444' }} />
        <div className='title'>You are not authorized to view the app.</div>
      </ErrorContainer>
    </Root>
  );
}
