import axios from 'axios';
import Amplify from 'aws-amplify';
import { setAppHeartBeat } from '../reducer/HeartbeatActions';
import { Auth } from 'aws-amplify';
import aws from 'aws-sdk';
import { serviceContext } from '../../provider/serviceContext';
import { setUserReducer } from '../../user/reducer/UserActions';
import { getUserData } from '../../user/reducer/UserService';
import { AC_CONSTANTS } from '../../utils';

export function awsInit() {
  getAwsConfigurations()
    .then(response => {
      if (response && response.data) {
        Amplify.configure({
          Auth: {
            userPoolId: response.data[0].userPoolId,
            userPoolWebClientId: response.data[0].userPoolWebClientId,
            region: response.data[0].region,
            identityPoolId: response.data[0].identityPoolId
          }
        });
        let heartbeatObj = {
          loading: false
        };
        heartbeatObj = { ...heartbeatObj, data: [...response.data] };
        setAppHeartBeat(heartbeatObj);

        Auth.currentSession()
          .then(user => {
            // currentSession
            console.log('User Session', user);

            loginUser();
            aws.config.region = heartbeatObj.data[0].region;
            aws.config.credentials = new aws.CognitoIdentityCredentials({
              IdentityPoolId: heartbeatObj.data[0].identityPoolId,
              Logins: {
                ['cognito-idp.us-east-2.amazonaws.com/' +
                heartbeatObj.data[0].userPoolId]: user.signInUserSession.idToken.jwtToken
              }
            });
            aws.config.credentials.clearCachedId();
            // XXsetAuthentication({
            //   documentListLoading: false,
            //   error: false,
            //   errorMsg: false,
            //   data: {
            //     loggedIn: true,
            //     sessionToken: user.signInUserSession.idToken.jwtToken
            //   }
            // });
          })
          .catch(err => {
            console.log(err);
            setUserReducer({});
          });
      }
    })
    .catch(err => {
      console.error(err.message);
    });
}

const loginUser = async () => {
  console.log('Check==>');
  let user = await Auth.currentAuthenticatedUser();

  console.log('user==>', user);
  const { attributes } = user;
  console.log('attributes==>', attributes);

  getUserData(attributes.email)
    .then(response => {
      if (response.ok) {
        const userObj = response.data[0];
        setUserReducer({
          ...userObj,
          userId: userObj.userId,
          firstName: userObj.firstName,
          lastName: userObj.lastName,
          userRoles: userObj.userRoles,
          userSkill: userObj.userSkill,
          clientId: AC_CONSTANTS.HARDCODING_clientId,
          // mode: AC_CONSTANTS.AGENT_MODE.OUTBOUND,
          sessionToken: user.signInUserSession.idToken.jwtToken
        });
      } else {
        console.error(response);
      }
    })
    .catch(err => {
      console.error(err.message);
    });
};
export const getAwsConfigurations = () => {
  return axios({
    method: 'get',
    url: `${serviceContext}/heartBeat/aws`,
    data: {},
    headers: { userId: 1, 'Content-Type': 'application/json' }
  }).then(({ data }) => {
    // return JSON.parse(data);
    return data;
  });
};
