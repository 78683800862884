import { useRedux } from '../../provider/hooks-for-redux';

export const [useAppConfig, { setAppConfig }] = useRedux(
  'app-config',
  {
    appLoading: false,
    error: false,
    errorMsg: '',
    appStatus: null
  },
  {
    setAppConfig: (oldState, newState) => ({
      ...oldState,
      ...newState
    })
  }
);
