import { createStore } from './createStore';

import { applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';

let store = null;
// export const getStore = () => (store ? store : (store = createStore()));

// example middle-ware
const logDispatch = store => next => action => {
  // console.log('dispatching', action);
  return next(action);
};

export const getStore = () => {
  return store
    ? store
    : (store = createStore(
        {},
        composeWithDevTools(applyMiddleware(thunkMiddleware, logDispatch))
      ));
};

export const setStore = initialStore => {
  // console.log('setStore');
  if (store != null) {
    console.warn(
      'Store is already initialized. Call setStore before the first getStore. This call will be ignored.'
    );
    return;
  }
  if (typeof initialStore.injectReducer != 'function')
    throw new Error('Store must support .injectReducer');

  return (store = initialStore);
};
