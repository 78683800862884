import { useRedux } from '../../provider/hooks-for-redux';

export const [useAppHeartBeat, { setAppHeartBeat }] = useRedux(
  'heart-beat',
  {
    loading: false,
    error: false,
    errorMsg: '',
    data: []
  },
  {
    setAppHeartBeat: (oldState, newState) => ({
      ...oldState,
      ...newState
    })
  }
);
