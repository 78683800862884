import React from 'react';
import Popover from 'react-bootstrap/Popover';

export const UpdatingPopover = React.forwardRef(({ popper, children, ...props }, ref) => {
  React.useEffect(() => {
    console.log('updating!');
    popper.scheduleUpdate();
  }, [children, popper]);

  return (
    <Popover ref={ref} content {...props}>
      {children}
    </Popover>
  );
});
UpdatingPopover.displayName = 'UpdatingPopover';
